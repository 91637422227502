import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
const Home = () => import( "../views/Home.vue");

const Loggedin = () => import( "../views/Loggedin.vue");
const Loggedout = () => import( "../views/Loggedout.vue");

const Devices = () => import("../views/Devices.vue") ;

const Dev = () => import("../views/Dev.vue") ;

const esp32atomhubswitch = () => import( "../views/devices/esp32atomhubswitch.vue") ;

const FirmwareWrite = () => import( "../views/devices/write.vue") ;

const AppSettings = () => import( '../views/AppSettings.vue');

const Kibana = () => import("../views/Kibana.vue");

const Tuya = () => import("../views/Tuya.vue");
const TuyaParent = () => import("../views/devices/tuya-device.vue");

const TuyaAirCon = () => import("../views/devices/tuya-airconditioner.vue");

const EERAirCon = () => import("../views/devices/eer-airconditioner.vue");

const SnowDevices = () => import("../views/SnowDevices.vue");

const SnowDevices2023 = () => import("../views/SnowDevices2023.vue");

const SnowDevice = () => import("../views/devices/snow.vue");
const SnowDeviceAlert = () => import("../views/devices/snowalert.vue");

const AlertSensors = () => import("../views/alert/AlertSensors.vue");
const SensorAlertSetting = () => import("../views/alert/SensorAlertSetting.vue");

const Sensors = () => import("../views/Sensors.vue");

const SensorState = () => import("../views/SensorState.vue");

const BoreholeApp = () => import("../views/borehole/app.vue");

const Borehole20240327App = () => import("../views/borehole/app-bk-2024-3-27.vue");

const BoreholeTestApp = () => import("../views/borehole/test.vue");

const BoreholeTest2App = () => import("../views/borehole/app_ optimizationtest.vue");

const BoreholeOld = () => import("../views/borehole/old.vue");

const Borehole2PointApp = () => import("../views/borehole/2point.vue");
const Borehole2PointDepthApp = () => import("../views/borehole/2point-depth.vue");

const CustomAPIUploader = () => import("../views/custom/api/Uploader.vue");
const CustomVisualization = () => import("../views/custom/ui/CustomVisualization.vue");

const CustomDashboard = () => import("../views/custom/dashboard/CustomDashboard.vue");

const FileListView = () => import("../views/file/FileList.vue");

const FileListViewV2 = () => import("../views/file/FileListV2.vue");

const LineChartView = () => import("../views/chart/LineChart.vue");

const MapChartView = () => import("../views/chart/MapChart.vue");

const MetricView = () => import("../views/chart/Metric.vue");

const CustomDateSelectorView = () => import("../views/chart/CustomDateSelector.vue");

const JSField = () => import("../views/chart/JSField.vue");

const ChartCreator = () => import("../views/chart/ChartCreator.vue");

const KDDIMaizuru = () => import("../views/KDDIMaizuru.vue");

const ImageView = () => import("../views/chart/Image.vue");
const ImageViewV4 = () => import("../views/chart/ImageV4.vue");

const PlateauView = () => import("../views/chart/PlateauView.vue");
const Jupyter = () => import("../views/chart/Jupyter.vue");

const Pod = () => import("../views/Pod.vue");

const Web = () => import("../views/Web.vue");
const Plateau = () => import("../views/Plateau.vue");

const APIList = () => import("../views/api/designer/APIList.vue");
const APIEditor = () => import("../views/api/designer/APIEditor.vue");
const IFrame = () => import( "../views/IFrame.vue");
const Directory = () => import( "../views/file/Directory.vue");

/*
const Command = () => import("../views/command/Command.vue.bk") ;
*/
const RelaySetting = () => import("../views/command/RelaySetting.vue");

import ProjectRoutes from "../projects/router/index";

// import TestRoutes from "../../test/router/index";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Route",
    component: Home
  },    
  {
    path: "/loggedin",
    name: "Loggedin",
    component: Loggedin
  },  {
    path: "/loggedout",
    name: "Loggedput",
    component: Loggedout
  },
  {
    path: "/settings",
    name: "AppSettings",
    component: AppSettings
  },
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/devices",
    name: "Devices",
    component: Devices
  },
 /* {
    path: "/device",
    name: "esp32atomhubswitch",
    component: esp32atomhubswitch
  },*/
    {
    path: "/kibana/:space",
    name: "Kibana",
    component: Kibana
  }, {
    path: "/control",
    name: "Tuya",
    component: Tuya
  }, {
    path: "/control/:device_id",
    name: "TuyaParent",
    component: TuyaParent
  }, {
    path: "/control/:parent_id/air-conditioner/:device_id",
    name: "TuyaAirCon",
    component: TuyaAirCon
  }, {
    path: "/control/eer-aircon",
    name: "EERAirCon",
    component: EERAirCon
  }, {
    path: "/snow",
    name: "SnowDevices",
    component: SnowDevices
  },{
    path: "/snow2023",
    name: "SnowDevices2023",
    component: SnowDevices2023
  }, 
  {
    path: "/alert/sensors",
    name: "AlertSensors",
    component: AlertSensors
  },{
    path: "/alert",
    name: "SensorAlertSetting",
    component:SensorAlertSetting
  }, 

  
  {
    path: "/snow/:node_id",
    name: "SnowDevice",
    component: SnowDevice
   },
   {
    path: "/snowalert",
    name: "SnowDeviceAlert",
    component: SnowDeviceAlert
   },{
    path: "/sensors",
    name: "Sensors",
    component: Sensors
  },{
    path: "/sensors/state",
    name: "SensorState",
    component: SensorState
  },{
    path: "/borehole/app",
    name: "BoreholeApp",
    component: BoreholeApp
  },{
    path: "/borehole/test",
    name: "BoreholeTestApp",
    component: BoreholeTestApp
  }
  ,{
    path: "/borehole/test2",
    name: "BoreholeTest2App",
    component: BoreholeTest2App
  }
  ,{
    path: "/borehole/old",
    name: "BoreholeOld",
    component: BoreholeOld
  }
  ,{
    path: "/borehole/2point-depth",
    name: "Borehole2PointDepthApp",
    component: Borehole2PointDepthApp
  }
  ,{
    path: "/borehole/2point",
    name: "Borehole2PointApp",
    component: Borehole2PointApp
  },{
      path: "/custom/api/uploader",
      name : "CustomAPIUploader",
      component: CustomAPIUploader
  },{
      path: "/custom/ui/:params+",
      name : "CustomVisualization",
      component: CustomVisualization
  },{
      path: "/custom/dashboard/:params+",
      name : "CustomDashboard",
      component: CustomDashboard
  },{
      path: "/file/list",
      name : "FileListView",
      component: FileListView
  },{
      path: "/file/listv2",
      name : "FileListViewV2",
      component: FileListViewV2
  },
  {
      path: "/chart/line",
      name : "LineChartView",
      component: LineChartView
  },{
      path: "/chart/metric",
      name : "MetricView",
      component: MetricView
  },
  {
      path: "/chart/jsfield",
      name : "JSField",
      component: JSField
  },{
      path: "/chart/map",
      name : "MapChartView",
      component: MapChartView
  },{
      path: "/chart/image",
      name : "ImageView",
      component: ImageView
  },{
    path: "/chart/imagev4",
    name : "ImageViewV4",
    component: ImageViewV4
},{
    path: "/chart/plateauview",
    name : "PlateauView",
    component: PlateauView
},{
    path: "/plateau",
    name : "Plateau",
    component: Plateau
},{
  path: "/chart/jupyter",
  name : "Jupyter",
  component: Jupyter
},{
      path: "/chart/creator",
      name : "ChartCreator",
      component: ChartCreator
  },{
      path: "/kddimaizuru",
      name : "KDDIMaizuru",
      component: KDDIMaizuru
  },{
      path: "/firmware/write",
      name : "FirmwareWrite",
      component: FirmwareWrite
  },{
    path: "/pod",
    name: "Pod",
    component: Pod
  },{
    path: "/web",
    name: "Web",
    component: Web
  },{
    path: "/api/designer/",
    name: "APIList",
    component: APIList
  },{
    path: "/api/designer/edit",
    name: "APIEditor",
    component: APIEditor
  },{
    path: "/chart/customdateselector",
    name : "CustomDateSelectorView",
    component: CustomDateSelectorView
  },{
    path: "/iframe",
    name: "IFrame",
    component: IFrame
  },{
    path: "/directory",
    name: "Directory",
    component: Directory
  },{
    path: "/dev",
    name: "Dev",
    component: Dev
  }, 
/*  {
    path: "/command",
    name: "Command",
    component: Command
  }, 
  */
  {
    path: "/command/relay",
    name: "RelaySetting",
    component: RelaySetting
  }, 
  
  // ...TestRoutes.routes,
   ...ProjectRoutes.routes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});



export default router;