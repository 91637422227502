
import { defineComponent } from "vue";

export default defineComponent({
  props: {isShow: Boolean},
data(){
      return {
        
        isPushEnabled: false
      }
  },
  watch: {
    // isPushEnabledの値が変わったときにlocalStorageを更新
    isPushEnabled(newValue) {
      (window as any).setItem('isPushEnabled', newValue ? 'true' : 'false');
    }
  },
  async mounted(this:any){

    await this.checkSubscriptionStatus();
  }, 
  
methods:{
      
  async checkSubscriptionStatus(this:any) {
    if ( !(window as any).getItem ){return;}
  const storedIsPushEnabled = await (window as any).getItem('isPushEnabled');
console.log( storedIsPushEnabled );
  // Set the initial value if not found in localStorage
  if (storedIsPushEnabled === null) {
    this.isPushEnabled = false; // Initialize the value
    ( window as any).setItem('isPushEnabled', 'false'); // Save initial value
  } else {
    // Use the stored value
    this.isPushEnabled = storedIsPushEnabled === 'true';
  }

  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration) {
        const subscription = await registration.pushManager.getSubscription();
        if (subscription === null) {
          // Subscription is missing or invalid, re-subscribe the user
          if (this.isPushEnabled) {

             await this.subscribeToPushNotifications();
          }
        } else {
          // Subscription exists and is valid
          console.log('Push subscription is active:', subscription);
        }
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
    }
  }
},
async handlePushToggle(this:any) {

if (this.isPushEnabled) {
  
if ( ! await  this.subscribeToPushNotifications()){

if (await this.unsubscribeFromPushNotifications()){

   this.$emit('show-flash',{ "message":"Push通知の設定が解除されています。","type": "error"}); 
  } 
  this.isPushEnabled=false;
} // Subscribe to push notifications when enabled
} else {
  await this.unsubscribeFromPushNotifications(); // Unsubscribe if disabled
  this.isPushEnabled=true;
}
},

// Subscribe user to push notifications


async subscribeToPushNotifications(this: any) {
  // Service Workerが登録されているか確認
  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration) {
    console.log('Service Worker was not found.');
    return;
  }

  // Push ManagerとService Workerが利用可能か確認
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      // サーバから公開VAPIDキーを取得
      const response = await fetch('/api/v4/webpush/publickey');
      const data = await response.json();
      const applicationServerKey = this.urlBase64ToUint8Array(data.publicKey); // VAPIDキーをUint8Arrayに変換

      // LocalStorageに保存された以前のサブスクリプションを確認
// LocalStorageに保存された以前のサブスクリプションを確認
const previousSubscriptionString =  await ( window as any).getItem('subscription');
const previousSubscription = previousSubscriptionString ? JSON.parse(previousSubscriptionString) : null;
      // 以前のサブスクリプションが存在すれば更新、なければ新規作成
      let subscription;
      if (previousSubscription) {
        console.log('Previous subscription found:', previousSubscription);

        // 以前のサブスクリプションが有効かを確認し、有効ならそれを使用
        const currentSubscription = await registration.pushManager.getSubscription();
        if (currentSubscription && currentSubscription.endpoint === previousSubscription.endpoint) {
          subscription = currentSubscription; // サブスクリプションをそのまま使う
          console.log('Using existing subscription:', subscription);
        } else {
          // 以前のサブスクリプションが無効の場合、新規に作成
          subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey
          });
          console.log('New subscription created:', subscription);
        }
      } else {
        // 以前のサブスクリプションが存在しない場合、新規に作成
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey
        });
        console.log('New subscription created:', subscription);
      }

      // サブスクリプションをサーバーに保存
      if (await this.saveSubscriptionToServer(subscription)) {
        (window as any ).setItem('subscription', JSON.stringify(subscription)); // 新しいサブスクリプションをLocalStorageに保存
        ( window as any ).setItem('applicationServerKey', data.publicKey); // VAPIDキーも保存

        // 成功メッセージを表示
        this.$emit('show-flash', { "message": "Push通知の設定を更新しました。", "type": "success" });
        return true;
      }

    } catch (error) {
      console.error('Failed to subscribe to push notifications:', error);
    }
  } else {
    console.warn('Push messaging is not supported.');
  }
  return false;
},
async unsubscribeFromPushNotifications(this: any) {
  try {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) return;

    const subscription = await registration.pushManager.getSubscription();
    if (subscription) {
      await subscription.unsubscribe();
      console.log('User unsubscribed from push notifications.');
      
      // Notify the server to remove the subscription
      await this.removeSubscriptionFromServer(subscription);
      return true;

    }
  } catch (error) {
    console.error('Error during unsubscription:', error);
  }
  return false;

},
// Convert VAPID key from base64 to Uint8Array
urlBase64ToUint8Array(base64String: string): Uint8Array {
const padding = '='.repeat((4 - base64String.length % 4) % 4);
const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
const rawData = window.atob(base64);
const outputArray = new Uint8Array(rawData.length);
for (let i = 0; i < rawData.length; ++i) {
  outputArray[i] = rawData.charCodeAt(i);
}
return outputArray;
},


async removeSubscriptionFromServer(subscription: PushSubscription) {
  try {
    const response = await fetch('/api/v4/webpush/subscription', {
      method: 'DELETE',
      body: JSON.stringify(subscription),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.ok) {
      console.log('Subscription removed from server successfully.');
    } else {
      console.log('Failed to remove subscription from server.');
    }
  } catch (error) {
    console.error('Error removing subscription:', error);
  }
},

async saveSubscriptionToServer(subscription: PushSubscription) {
  try {

    // サーバにサブスクリプションを送信
    const response = await fetch('/api/v4/webpush/subscription', {
      method: 'POST',
      body: JSON.stringify(subscription),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.ok) {
      console.log('Subscription saved to server successfully.');
      return true; // 成功時に true を返す
    } else {
      console.log('Failed to save subscription.');
      return false; // 失敗時に false を返す
    }
  } catch (error) {
    console.error('Error saving subscription:', error);
    return false; // エラー時も false を返す
  }
}

}
});
